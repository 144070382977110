import styled from "styled-components";

export function Title() {
  return <_Title>
    THE CULT OF<br />THE WRIGGLER
  </_Title>
}

const _Title = styled.div`
  margin: 35px 0;
  color: var(--wrigglerRed);
  font-size: 2.25rem;
  line-height: 1.2em;
  text-align: center;
  font-weight: 700;
`