import {InfinityAnimation} from "../components/InfinityAnimation";
import {Button} from "../components/shared/Button";
import {ethers} from "ethers";
import {useWeb3Context} from "../web3-context/Web3Context";
import EdwoneAbi from "../custom-abis/Edwone.abi.json"
import PactAbi from "../custom-abis/Pact.abi.json"
import {edwoneAddress, pactAddress, isLocalDev} from "../settings";
import {useEffect, useState} from "react";
import styled from "styled-components";

const ContentDiv = styled.div`
  color: white;
  text-align: center;
`;

const Section = styled.div`
  margin: 2em;
`;

const Link = styled.a`
  color: var(--felixGreen);
`;

const Hightlight = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5em;
`;

const Title = styled.h1`
  margin-bottom: 10vh;
  color: var(--wrigglerRed);
  font-size: 2.25rem;
  line-height: 1.2em;
  font-weight: 700;
`;

function Pact() {
  // Signatures from scripts/data/SignaturesExport.json
  const allowList : any = isLocalDev ? {
    "0x70997970c51812dc3a010c7d01b50e0d17dc79c8": "0x19ae84abd2ac6e07d86b2817e84151094fa2fb9a2eed77069d175ac63576e2a5007cd13e3652d3ce1cf8177ace338d7a6dbf1f5da4730efffc00d9546f9ba5e51b",
    "0x3c44cdddb6a900fa2b585dd299e03d12fa4293bc": "0xeca486a99d719190dc88717753543be7f7fe2fb07e091770f61ff4f9f2aea63a7c5014221e826073b0aedf481edb79a33a117bf9508dcf47bf249d78ae6b650d1c",
  } : {
    "0x006a8a496889e9983a5256f34b608ee9569ba319": "0x0df44df33a86d34eb1100f7c83fc071b027ee075bdc6504133b4e0202511f76e7cab6c223b821a716da1b4f7c4ebc4ed9568309fa1c02f81558dcb48e04271f91c",
    "0x00ef256c967703ec84acdbee0b9690d3bbcbd6dc": "0xa9a50382a25cbbce0476f8df37bea79d3b757bec5705da7313eb93128d3b84b130de00453271ae521023b0ca44091c28970b8226de5a3c9460ed1741aa0f11a91c",
    "0x04260d88482651e914eefdcd6b3ca33a2bb81d41": "0x870ecf20a8ef48c3d785e4c2017f2133c9fd73fe831a7950321f237900bf71193d4eb4d903aa4818e54df969a1fdb248be17334c175938e8ce061b9471cd5beb1c",
    "0x061723e247dc58b10d1f5f1b6e536ab26ba4ce22": "0x7e7c0779fdd0fe18068ebe2f3f923792df1150287270d504830714006143be0b2e50b30c07961987a14bfdb656981eda1d178ac459532adee42ec017ba2500ab1c",
    "0x08267cb203b4cf1066a81b0f218dddd8f0e33e1e": "0x432ccd4aba71f99758be9bb1172c341e203a0e3a74c9411f395685b144d305d14a974ad8d001f017c03b7dd65f65e01afba41033046e078a6636c8d6af550de71c",
    "0x0e3b08406f9f3fd3c10912edbb92777227eb0a97": "0x67e4f51df113a95adbe24cbfa71895ab5b6f8def463636e831183b926943ded45931de230a02324c8fd2aa1624a51950f681689b1a87b1cc99d9232d4a014e401c",
    "0x17476d0ed31f81d95b5ba8960b2d0b4de4675e64": "0xda395106af22e063f623d6fdd5317142c176e6fee7a2399a6f457ac2167b6742386f8a74983df1c49e12bf03e8b96907635aeb892df8ff544ecbee708d937ec61b",
    "0x18b24cf58dd392b9dad6ede481300d33309f3595": "0xc45f423385715ffd063b987d5c1443f16ec2b439dcf29c25375da89e7a1e14816891b9b141cee0b828a43500571ee34073f713e5ba740d0797cb31b4d10df0311c",
    "0x1ccb7bcfce8efe4c3adfd3396468beb69260317d": "0xd1752c32d8cee0fbf8a01735eebcf3ce7adc3a26e9fca1da095f753473630f23456448425901beed250d9e42013ce862cc5cea554d559c2de4bfd4e9302db5001c",
    "0x20c467db9b9fe0fa39d879b3f23c475582da2fba": "0xdcf9023cfe16c5c9b9b56280ded0f23d80279989a4abb0514e083c6411755ce270853f21b6cc7ac176167489932042202646df3c8bc4c68c464b568fc3a041941c",
    "0x225ef95fa90f4f7938a5b34234d14768cb4263dd": "0xfd7de3983200e346d49d4bc3c7be55df90663798fd0b55351c1cb2d1fb50583c410baee84be9a936d61428ec78f27e0d00d29a753770ef689045de01e9eb620b1b",
    "0x28e3d831cb9939a98ffc8f29f9b6d1afbb410af6": "0xd453e1630d81e09fd1fbebad9add8c9b4d3b6233fdfd74e2ac17963b99051e8f671a1024fccfa3588b761ebfc48c0c4894f0d18c75a21d159c7f353f3dcb2b2a1b",
    "0x29af6deecbe9cbe9dd545b0b25bd9d26973d3572": "0x2b034e9bc68d6af25cb4ca9948e0e532437271641ceb6cf4582f1ce05cfa4721508c4dd15c3ef6d6b46c593b55b81130dbce67881cda685fc8c992da3ad71f0d1c",
    "0x2b6e1413d6c35657452ff16dc9c7f13c643593e5": "0x129b5b28f7b80e87a11c8e74b28a767bbc900665b44ac5d3d9deef0aa470e30c77254423b7db4eca36344e04db833ce5f36de070de1ad848db1bab9c7ca5e59e1c",
    "0x32e263cb9003f7fef329080bdcbc82f5cfd6c02f": "0xd61fbc240a2aabdc72791b7743940eedf7d62b9898a0c0c3c03764b18114194e1e9aea4dc8579b8b4558d4d2783653a2ee93e275fe79dff9fed8763c71045c4b1c",
    "0x3544f9b6e55db1727be0becfbc71751b04aeebb0": "0xb2de66fffbd6c1df33fb9dbc4e38bb863bb512678cc8d9dd9a024d5c9e2d2fe31ff3ced76229e2601c1226e0af490636ba1f39956461d80dc255694fb40ffccf1b",
    "0x3d9456ad6463a77bd77123cb4836e463030bfab4": "0x2417eb33c7b10783b4511158194ff736688cc0389bb39a5698c684794e036436574f4d1d9086cfa3970d2edf0773c3fe29c10f0faf7b2996eef160815a7993a81c",
    "0x3f9f89bbcc6302f2076c06fcdd18e4c824fbf941": "0xb8a22b54b6148d66fccba38e2cf51fd53bc8d6864f4f085a5a8c4d551de1f0874300afd5199f8a172848e135600b9ca990fc93043301f519c41457a9b79758e71b",
    "0x40158e1d5eaa2dc589552464010fe81b1b3a23f5": "0xd52de6cd32330d2f9d3662b1c69c1494af21cfa3e0a2a8a7cafb1f637786d5ca12ed78637baf0c86bf72b241cae60393da34ec52b0a4bb142e20d44ae2f0d6041b",
    "0x436a257ab8f9d8579594f493ffb81fa5245ada26": "0xec309bce72d9e06e6757808a2c22cdcd97134b731177aad51b825a10d0dbde7609b33255fe12fe3536a4a42b248f0f7d7ea92d24e192571d87094a4b577ca05e1c",
    "0x46797eab11aae32e2aa4c5058f71b072cde48dfa": "0xc4f85eeedf2c9f0d1ae3b8e1f2770a81f178837f0c6e2f2c2686716deb5bb2b81568a7c3b0c2db207b8cbbf09eb52db6c86365e1b0639c8d8b552cdb4ef01a421b",
    "0x4783caa6645b566465978b5d19cd55329ae6e964": "0xe48e829b0c1ab10c0fe101f7e1358f9752d2fc6d7078cebbc645e99a4c4264f95cbf2ccbee454ccbc4c9aeab056b347109ce8cb078bf6b7d3013d8492906bbb91b",
    "0x4c85efd6655bd3ca9eab49970588d81b2ceee3eb": "0x60538d705949952ee61b14763770b3ecb87b0a5c1df1b0aacefaa7f0673f27021ab72da62af0a3a27034860a196888d88cfa9fcb9641a6910fcf5954084a45df1c",
    "0x5baa13b887445687059fceb4490a9837537ff405": "0x06baee63d04f6067d2df2dbd9c756607bc435cd61fafb9d122fa86d050bae8372c4436a88cebdf726979cf9091db35c9b02bf72749a33d87f5b1c4959b4fc6671c",
    "0x5bfeb4ca066c9458842ac89b6e5cd983bd1a1034": "0x6e35f435f173db93204599f2f77d2086876c6af921c65bd9f08c57a09586f4b82414e1b2bd53560088443e0a521a0fbda5b2031d503f849bd9f5e54596d44a321b",
    "0x5f429371c3b930d3998a9f06b0ba56d7ae9d21cd": "0x70c0161648f27fcc7bf72f2dfc16e6a4afb177c3318eed477d6772dcc0544f291fb65472bca9f0d9e065a3ba3e617f8094fec67055134ac812b04695de97425a1b",
    "0x6457c04405f5e89a8ee6ee15de00cea7e4298c1d": "0x180229faea3690c067054beb906018144b5dc6ba4657ed55b5c3307582defcf7793e4d571652b4d0a41007cf8c4937676b25132d79b46778de167343ff6cc8e61c",
    "0x70d9f98ea60658fd81cc54086006e949aee207f3": "0xf758aff5eab931fd2f5d469ba034217d1932ca99b197629d1650a952f6aa1fd7203f0f7575200c48b0f98226747244efa63b7b3e07c28013b6adc2d7a6e7ffbd1c",
    "0x829949946ab1e87ebf9431207ab8d5cf8f1372c6": "0xb472ce6012f3cd7092ee0d3cb41451d2ac2c02c2c5333c2cfb3bec0f0c29976c24f02062097f096b52ebcc2076c1aa968df2f7b62b64edec6d0949106381f0891b",
    "0x8380bb3e5450b19d64886ed90a30af7570505cfc": "0x86f1bb1b74e966cf85cd2e340a99d55011a23c2686d0e07a875a5660aea490465a267f19aa464fd30d4a945ed67a2098194a9c19ac73d8b800fbd20aff594fd81b",
    "0x8c7e486cf9020a40e05119e2f7523bafcdd8a1c3": "0x9c72786df7d84724a5977a314c9b1f0758835bf212902194825d1e10071307221c1541414edb69717dff76666936f6e739c8eeabea81fea7c3d0195b7c7f12421b",
    "0x9650ee73e4c26595331660dbe98662532688f72c": "0x06f56d204b41bf791bd84482fcd8f7ca438539c9e245e46faa8b38cdbc4a17d130d42a341108d5cf1a1f997bdbaa227cb3ed381f0f76a712340332109c78f0ea1b",
    "0x97dfa8a7d0f5c29234bfc15569700c0d4fb5437f": "0x74c0de482585ab6c697e0c0522ef1db4ca924ef0b8ba85fcd9755ab7206cbb9959595baa573f536df1556a39d9cd373e54dacc6b9296f4cf2c1f7c80e0750a5c1b",
    "0x9f8c1ed0334b06d62d04e6bfe4c8a3adb2e15ad4": "0x9b06e1fbdb92d4c9ca0d4767028272c55bbaeeb510b0cbffe1809969b98e25516816e0ac46a4b167b9eca180dc2f8728e7997718e0ef74c367c994ac86fc88be1b",
    "0x9fef296d8dc2e5c3ceae453b88ea69632d7b4a5e": "0xce5e723b414a20f6255ff04792cbc224705757957e02d7550fbde03ddfcff77855458fa9850f4b6eb0242bbe55fe70121437461b69d936ebaa9da2e59a9af60b1c",
    "0xa17138c0675173b8ea506fb1b96fa754bc316cc2": "0xf418b2e2543d93dd4c116512285dbbed7e64da4d2e8fcdeb2d4124f864945b9e62cfe3348543fb99bb786e56f1c3276a12e63407c784f5d7775464862343b65b1c",
    "0xa1733bc95272fdfb3d14b93e93aab1290b3ab7fe": "0x2995565b67fcc7ad2b60b1939f2e7ff5c67be9aa202da83a99e5d6ac682566f615bc0f87f045d113adff67497927205228988b7b774ba7deb9235b43aae0a6511c",
    "0xb4b5690d2513049e7b519c7ec21da6355552b15a": "0x578fe006e867ad55a4ae0a41bb23c7a4636a412e0bbab5339064b47b378fbcf93e8081c1c4134fbd4c608510a8fc880402c2cc67721444f1000d7de7a017d6b61b",
    "0xb7d3a787a39f25457ca511dc3f0591b546f5e02f": "0x0a258188e47843006fe17892586df6688e6c14dd3a2f354da955dd237f1e73380cbf6c2d02d627eb8047b28dcb2a4867142beaa6ef530c3006ebb6b5bc23f9901c",
    "0xc3e5416d13f15e58699fa043910ce28dd341411f": "0xe37a7028a22d198c4843d007508ad719bd3b78690962a9e9eee19a8f9e10b2421b5059a21a29b9c17a112c97bfc063eeda0d76cb880d0f6535b0ff4209841aac1b",
    "0xc7d7800cf372657326593e56b9136e048cc94710": "0x0ed0e29d900966b1ed92d40cb3ec42bb203fcd2717a09c1f577d0e3cb903cc343b30d9913d0730aea3122a9e28e939700fc05b6e9413e5e2d38d18e3f34f14791c",
    "0xc881ba24fea61d9c629bb7eb79b3b20392584115": "0x1033407eacd79b41861c43eb5038fa247f6de8ba5bedb14afdb5a03913f13e4b6081e3f3ac8d1ff34691381a39ad925afe70b655c58cfd537f2ae64af35b38d01b",
    "0xc94dd5caa29fb3681f08594668db10bdb1ebf316": "0x65b7a22aa44387fa0b5588e8e506e7caffa074bb54ca946b23e5674010d0142f5954164975a688bf3882387afb33ef98cc910afa4c6f3cfa43a77873ff0951db1b",
    "0xca839b39d13868ccbf7f1fa509ac5e750a0e81b1": "0x6f65aaea2b5b64f5404dd46e2dfaec470ee3583f8bb8df7dc3b320b7ad030cda2d384a5f34b9c57dcd23c37feb0c3347143c178e9ab4730662ad61343fbccadc1c",
    "0xd1a5b91957530e1b3e9cfac1543467c60c352f69": "0x19ce89ecd55543804bf239a071860cf0d0448c36fd91c197774a7dc4d4fae82376a82b4e44159aa89f626da777221436eea2d51cb8ebbd847c7deddcf89b3a0a1b",
    "0xd1b62db7ad09e615a4603e89c2f25fe8ad67e596": "0xacbdd6e01a8dff67601ed687cd9d338c066d6e75d8f5d8bd9e6e93a1728d11f15ddd61eb2d1f5f1a3043134c7c34ee4636bab1827bad5b648e2c748102a713f11b",
    "0xd216c30b24dddcc69e781813e0cb6fc17768a6f7": "0xee9540a59fc39fbc64184189e575880c0363d5f336ff42b0c9477a87e2335e5e556c38de46d3231b81f17325c840fe9f17a2a2213954f9dc2775cfd9562f94601b",
    "0xdae48d7b5c73a95f74caae08f7f92d3b7927f449": "0x6498d460ad65723b94903adaf58a4c3d9f4ddb91581339fa10ab2b2b97a71d7b69b1a24822dba0960bc8857b6323271092bf6da60c5b55eaa5dd89b37ca78ec11c",
    "0xe0b7fc12b6829880aaade5b33c92eb3b592e3f15": "0x48243ce95b6c70a632e1ae4f9e0da63ddabe016b62ebb0ffb342e5800d5d3cd55187cf49f25b81d1e8a9d1cc3694c8ec319ef65c5cec2a3cc689fe14c5fa94de1b",
    "0xe1c163f5eacd153803104f189018ee58750e628c": "0x3c15d11509aeec312c062af8d48fed1f3e8b80bf90795d969a9f5a569592137960268deae51463c47190b565f95a4fdd330a0b9529025b94543a526d2549e74f1b",
    "0xe3672ddd7cf52ba8d5f4413e7bb5bbb5e7dc0efd": "0x53306dff603a376c9fe2b3c97204112359c39a190c0eee0c946110c244a7dde54e32f35456d7b8b9e852f55146aa97ba5c7b239b8d6e0d56973b1e6c2b9e58f31c",
    "0xe3f27deff96fe178e87559f36cbf868b9e75967d": "0x2b03aa8a34764536b3541e08eea2f9082d933814e22d0d716eb8925b3b2fbf073832a9e876a5a7258296e43b78ea619fd507f96434c994e79f46372080be0e211c",
    "0xfb469965ec425d5b83da58dd75a3a77ac06eafa1": "0x9d0240587f2162b2bfc731969c4a55521d23afd94b83bed9ea3095044a38914672be7b96d0eac44cc113387946dc881b84ac814b66ab8ede4c1dd5572aabbd771c",
  };

  const { provider, activeAddress, currentBlockNumber } = useWeb3Context();
  const [approved, setApproved] = useState(false);
  const [joined, setJoined] = useState(0);
  const [pactOpen, setPactOpen] = useState(true);
  const [transactionHash, setTransactionHash] = useState("");
  const [memberMessage, setMemberMessage] = useState("\u00A0");

  const edwone = new ethers.Contract(edwoneAddress, EdwoneAbi.abi, provider);
  const pact = new ethers.Contract(pactAddress, PactAbi.abi, provider);

  const connected = !!activeAddress;
  const eligible = connected && !!allowList[activeAddress.toLowerCase()];

  useEffect(() => {
    (async () => {
      if (!pactOpen) return;

      const countRequest = pact.pactSize();
      const openRequest = pact.enrollmentOpen();
      if (eligible) {
        setApproved(await edwone.isApprovedForAll(activeAddress, pactAddress));
      }
      if (approved) {
        setJoined((await pact.devoteeIndex(activeAddress)).toNumber());
      }

      setPactOpen(await openRequest);
      const count = await countRequest;
      setMemberMessage(
        !pactOpen ? `${count} members received The Worm.` :
        count == 0 ? "Be the first to join!" :
        count == 1 ? "Only one has joined." :
        count > 1 ? `${count} have already joined.` :
        "\u00A0"
      );
    })()
  }, [currentBlockNumber, activeAddress]);

  async function handleApprove() {
    const txPromise = edwone.connect(provider.getSigner()).setApprovalForAll(pactAddress, true);
    let tx;
    try {
      tx = await txPromise;
    } catch (e) {
      console.error(e);
      return;
    }
    setTransactionHash(tx.hash);
    await tx.wait();
    setTransactionHash("");
    setApproved(true);
  }

  async function handleJoin() {
    const signature = allowList[activeAddress?.toLowerCase() || ""];
    console.log(signature);
    const txPromise = pact.connect(provider.getSigner()).join(signature);
    let tx;
    try {
      tx = await txPromise;
    } catch (e) {
      console.error(e);
      return;
    }
    setTransactionHash(tx.hash);
    await tx.wait();
    setTransactionHash("");
    setJoined(-1);
  }

  return <ContentDiv>
    <InfinityAnimation />
    <Title>Pronto Pact of the Protectors</Title>
    {
      pactOpen ? <div>
        <Section>
          <div>{ Object.keys(allowList).length } addresses are eligible to join the pact.</div>
          <Hightlight>{ memberMessage }</Hightlight>
        </Section>
        <Section>{
          !connected ? <span>Connect your wallet to check if it is eligible to join the pact.</span> :
          !eligible ? <span>Sorry, the wallet connected is not eligible to join the pact.</span> :
          !approved ? <div>
              <p>You are eligible! To join you must approve transfer of The Worm.</p>
              <Button onClick={handleApprove}><div>Approve</div></Button>
            </div> :
          joined == 0 ? <div>
              <p>You are ready!</p>
              <Button onClick={handleJoin}><div>Join</div></Button>
            </div> :
          joined > 0 ? <span>Congratulations! You are pact member {joined}.</span> :
          <span>Congratulations! You are in the pact.</span>
        }</Section>
      </div> : <Section>
        <div>The pact is complete.</div>
        <Hightlight>{ memberMessage }</Hightlight>
      </Section>
    }
    {
      transactionHash.length ? <Section><Link href={"https://etherscan.io/tx/" + transactionHash}>view transaction on etherscan</Link></Section> : ""
    }
  </ContentDiv>;
}

export default Pact;
