import {InfinityAnimation} from "../components/InfinityAnimation";
import {Title} from "../components/Title";
import {TheWriggler} from "../components/TheWriggler";
import {HeathenList} from "../components/HeathenList";
import {TransferTheWriggler} from "../components/TransferTheWriggler";

function WrigglerList() {
  return (
    <div>
      <InfinityAnimation />
      {/* <TransferTheWriggler /> */}
      <Title />
      <TheWriggler />
      <HeathenList />
    </div>
  );
}

export default WrigglerList;
