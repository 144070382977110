import styled, {css, keyframes} from "styled-components";
import TheWrigglerSvgRaw from "../svgs/wriggler.svg"
import TheWrigglerXSvgRaw from "../svgs/wrigglerx.svg"
import {useWeb3Context} from "../web3-context/Web3Context";
import {Contract, ethers, Signer} from "ethers";
import {theWrigglerAddress, yoinkPowerExtractionChamberAddress} from "../settings";
import TheWrigglerAbi from "../custom-abis/TheWriggler.json";
import YoinkPowerExtractionChamberAbi from "../custom-abis/YoinkPowerExtractionChamber.json";
import UnimportantAbi from "../custom-abis/Unimportant.json";
import React, {useEffect, useRef, useState} from "react";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {Button} from "./shared/Button";
import {BigButton} from "./shared/BigButton";
import {useIntervalJobQueue} from "../hooks/useIntervalJobQueue";
import {usePrevious} from "../hooks/usePrevious";
import {Description} from "./description";

type MaybeNumber = number | null

const HEAL_DELAY_MS = 3000

export function TheWriggler() {
  const { activeAddress, currentBlockNumber, provider, signer, connect } = useWeb3Context()
  const [hasWriggler, setHasWriggler] = useState(false)
  const [damages, setDamages] = useState<number[]>([])
  const [lastDamageBlock, setLastDamageBlock] = useState<number>(0)
  const [lastHealBlock, setLastHealBlock] = useState<number>(0)
  const [stamina, setStamina] = useState<number>(-1)
  const previousStamina = usePrevious(stamina)
  const [hasYoinkPower, setHasYoinkPower] = useState(false)
  const [isHeathen, setIsHeathen] = useState<number>(0)
  const [magic, setMagic] = useLocalStorage('magic', '')
  const [checkedIsDefeated, setCheckedIsDefeated] = useState(false)
  const [isDefeated, setIsDefeated] = useState(false)

  const [yoinkPending, setYoinkPending] = useState(false)
  const [yeetPending, setYeetPending] = useState(false)
  const [yeetTarget, setYeetTarget] = useState('')
  const [yeetTargetValid, setYeetTargetValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const lastSeenBlockNumber = useRef<null | number>(null)

  const { addJob } = useIntervalJobQueue(4000)

  const theWriggler = new ethers.Contract(theWrigglerAddress, TheWrigglerAbi.abi, provider)
  const yoinkPowerExtractionChamber = new ethers.Contract(yoinkPowerExtractionChamberAddress, YoinkPowerExtractionChamberAbi.abi, provider)

  useEffect(() => {
    if (magic === '') {
      setMagic(getMagic())
    }
  }, [])

  const combo = damages.length > 1 ? damages.length : 0

  const Damages = () => <DamagesWrap><DamagesContainer>
    {damages[0] ? <DamageBox offsetX={23} offsetY={40} key={`${lastDamageBlock}-0}`} >-{damages[0]}</DamageBox> : null}
    {damages[1] ? <DamageBox offsetX={45} offsetY={51} key={`${lastDamageBlock}-1}`} >-{damages[1]}</DamageBox> : null}
    {damages[2] ? <DamageBox offsetX={70} offsetY={65} key={`${lastDamageBlock}-2}`} >-{damages[2]}</DamageBox> : null}
    {damages[3] ? <DamageBox offsetX={28} offsetY={75} key={`${lastDamageBlock}-3}`} >-{damages[3]}</DamageBox> : null}
    {damages[4] ? <DamageBox offsetX={12} offsetY={60} key={`${lastDamageBlock}-4}`} >-{damages[4]}</DamageBox> : null}
    {combo > 1 ? <ComboBox offsetX={58} offsetY={36} key={`${lastDamageBlock}-combo`} >{combo}&nbsp;COMBO!</ComboBox> : null}
  </DamagesContainer></DamagesWrap>

  const showHeal = previousStamina && previousStamina !== 10_000_000 && stamina !== -1 && stamina !== 0
  const healDelay = damages.length ? HEAL_DELAY_MS : 0

  const Heal = () => <DamagesWrap><DamagesContainer>
    {showHeal ? <HealBox delay={healDelay} offsetX={10} offsetY={20} key={`${lastHealBlock}-heal`}>+500</HealBox> : null}
  </DamagesContainer></DamagesWrap>


  useEffect(() => {
    (async () => {
      const _isDefeated = await theWriggler.isDefeated()
      setIsDefeated((prevIsDefeated) => prevIsDefeated || _isDefeated)
      if (isDefeated || _isDefeated) {
        setStamina(0)
      }
      setCheckedIsDefeated(true)
    })()
  })

  useEffect(() => {
    if (currentBlockNumber && provider) {
      if (lastSeenBlockNumber.current === null) {
        lastSeenBlockNumber.current = currentBlockNumber - 1
      }
      const firstBlockToCheck = Math.max(lastSeenBlockNumber.current as number + 1, currentBlockNumber - 10)
      lastSeenBlockNumber.current = currentBlockNumber
      const lastBlockToCheck = currentBlockNumber
      let blockToCheck = firstBlockToCheck
      ;(async () => {
        if (isDefeated) return
        while (blockToCheck <= lastBlockToCheck) {
          const [
            damageLogs,
            _stamina,
            _isDefeated,
            _isHeathen,
            _wrigglerOwner,
            _yoinkPowerReceiver
          ] = await Promise.all([
            theWriggler.queryFilter(
              theWriggler.filters.Damaged(),
              blockToCheck,
              blockToCheck,
            ),
            theWriggler.stamina({ blockTag: blockToCheck }),
            theWriggler.isDefeated({ blockTag: blockToCheck }),
            theWriggler.isHeathen(activeAddress || '0x0000000000000000000000000000000000000001'),
            theWriggler.ownerOf(0),
            yoinkPowerExtractionChamber.yoinkPowerReceiver(),
          ])
          setHasWriggler(_wrigglerOwner.toLowerCase() === activeAddress?.toLowerCase())
          setIsHeathen(_isHeathen)
          const jobBlockNumber = blockToCheck
          addJob({ fn: () => {
              console.log('processing job for block', jobBlockNumber, new Date().toISOString())
              const damageValues = damageLogs.map((damageLog) => {
                return damageLog.args!.damage.toNumber()
              })
              console.log(`hit ${damageValues.length} times!`, damageValues)
              setDamages(damageValues)
              if (damageLogs.length) {
                setLastDamageBlock(jobBlockNumber)
              }
              if (_stamina.toNumber() !== 10_000_000) {
                console.log(`setting stamina to ${_stamina.toNumber()}, healing`)
                setStamina(_stamina.toNumber())
                setLastHealBlock(jobBlockNumber)
              } else {
                console.log('full health!')
                setStamina(_stamina.toNumber())
              }
              setHasYoinkPower(_yoinkPowerReceiver.toLowerCase() === theWrigglerAddress.toLowerCase())
              setIsDefeated(_isDefeated)
            } }
          )
          blockToCheck++
        }
      })()
    }
  }, [currentBlockNumber])

  // const debugText = Object.entries({
  //   hasWriggler,
  //   damages,
  //   stamina,
  //   magic,
  //   isDefeated,
  //   currentBlockNumber,
  // }).map(([key, value]) => <div>{key}: {`${value}`}</div>)

  async function handleYoink() {
    if (!activeAddress) {
      connect()
      return
    }
    try {
      setErrorMessage(null)
      const txPromise = theWriggler.connect(signer as Signer)['yoink(uint256)'](
        magic, {
          gasLimit: `0x${(180000).toString(16)}`
        }
      )
      setYoinkPending(true)
      const tx = await txPromise
      await tx.wait()
    } catch (e) {
      console.error(e)
      setErrorMessage((e as Error).message)
      setYoinkPending(false)
    }
    setYoinkPending(false)
  }

  async function handleYeet() {
    if (!activeAddress) {
      connect()
      return
    }
    try {
      setErrorMessage(null)
      const txPromise = theWriggler.connect(signer as Signer)['yeet(address,uint256)'](
        yeetTarget,
        magic, {
          gasLimit: `0x${(180000).toString(16)}`
        }
      )
      setYeetPending(true)
      const tx = await txPromise
      await tx.wait()
    } catch (e) {
      console.error(e)
      setErrorMessage((e as Error).message)
      setYeetPending(false)
    }
    setYeetPending(false)
  }

  const YoinkButton = ({ enabled }: { enabled: boolean }) => {
    return <ButtonWrap>
      <BigButton loading={yoinkPending} enabled={enabled} onClick={handleYoink}>
        <div>YOINK</div>
      </BigButton>
    </ButtonWrap>
  }

  function handleYeetInputChange(event: any) {
    setYeetTarget(event.target.value)
  }

  const YeetButtonWithInput = ({ enabled }: { enabled: boolean }) => {
    return <div>
      <YeetInput onChange={handleYeetInputChange} value={yeetTarget} spellCheck={false} />
      <ButtonWrap>
        <BigButton loading={yeetPending} enabled={enabled} onClick={handleYeet}>
          <div>YEET</div>
        </BigButton>
      </ButtonWrap>
    </div>
  }

  const buttonToShow =
    isDefeated ?
      null
    : hasYoinkPower ?
      hasWriggler ?
        <YeetButtonWithInput enabled={true} />
      : isHeathen ?
        <YoinkButton enabled={false} />
        : <YoinkButton enabled={true} />
    : <YoinkButton enabled={false} />

  return <>
    <div>
      {/* <DebugText> */}
      {/*   {debugText} */}
      {/* </DebugText> */}
      <Damages />
      <Heal />
      <HealthBar maxStamina={10_000_000} stamina={stamina} />
      <_TheWriggler hasBeenDamaged={!!lastDamageBlock} key={lastDamageBlock}>
        <TheWrigglerSvg hasBeenDamaged={!!lastDamageBlock} hasBeenDefeated={isDefeated} src={TheWrigglerSvgRaw} />
        <TheWrigglerXSvg hasBeenDamaged={!!lastDamageBlock} hasBeenDefeated={isDefeated} hasHolyPower={true} src={TheWrigglerXSvgRaw} />
      </_TheWriggler>
      {buttonToShow}
    </div>
    <Description isDefeated={isDefeated} />
  </>
}

const YeetInput = styled.input`
  margin: -20px auto 30px;
  display: block;
  background-color: transparent;
  border: 1px solid #ff95d6;
  border-radius: 3px;
  padding: 5px;
  width: 365px;
  color: #ff95d6;
  text-align: center;
  
  &:focus {
    display: block;
    background-color: transparent;
    border: 1px solid #ff95d6;
    border-radius: 3px;
    padding: 5px;
    color: #ff95d6;
    outline: none;
  }
 
}
`

const ButtonWrap = styled.div`
  display: block;
  margin: -20px auto 80px;
  width: 132px;
`

interface HealthBarProps {
  maxStamina: number,
  stamina: number
}

function HealthBar({ maxStamina, stamina }: HealthBarProps) {
  return <HealthBarWrap show={stamina !== -1}>
    <HealthBarContainer>
      <HealthBarBar percent={stamina/10_000_000}/>
      <HealthBarText>HP: {stamina.toLocaleString()} / {maxStamina.toLocaleString()}</HealthBarText>
    </HealthBarContainer>
  </HealthBarWrap>
}

const HealthBarWrap = styled.div<{ show: boolean }>`
  max-width: 600px;
  margin: 0 auto 0;
  padding-left: min(1.5vw, 18px);
  position: relative;
  opacity: ${({ show }) => show ? '1' : '0'};
  transition: all 0.2s ease;
`

const HealthBarContainer = styled.div`
  position: absolute;
  right: min(10vw, 66px);
  top: min(20vw, 120px);
  width: min(40vw, 240px);
  height: min(2vw, 12px);
  border-radius: 10000px;
  background-color: var(--accentPurple);
`

const HealthBarBar = styled.div<{ percent: number }>`
  margin: min(0.5vw, 3px);
  color: var(--wrigglerRed);
  /* full is 39vw */
  width: min(${({ percent }) => css`(${percent * 100 * 0.973}%)` }, 234px); 
  height min(1vw, 6px);
  border-radius: 10000px;
  background-color: var(--wrigglerRed);
`

const HealthBarText = styled.div`
  margin-left: min(2vw, 12px);
  margin-top: min(1.5vw, 9px);
  font-size: min(1.88vw, 11px);
  font-family: "DePixel", Monaco;
  color: white;
`

function getMagic() {
  const nibbles = []
  for (let i = 0; i < 64; i++) {
    const nibble = Math.floor(Math.random() * (15 - 0));
    nibbles.push(nibble.toString(16))
  }
  return `0x${nibbles.join('')}`
}

function shuffle(array: any[]) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  const indexArr = []
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    indexArr.push(randomIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return [array, indexArr];
}

const KillKeyframes = keyframes`
  0% {
    transform: rotate(0) translate(0, 0);
  }
  
  100% {
    transform: rotate(-575deg) translate(290px, -100px);
  }
`

const FlinchKeyframes = keyframes`
  0% {
    transform: rotate(0) scale(1, 1);
  }
  25% {
    transform: rotate(3deg) scale(0.95, 1.05);
  }
  100% {
    transform: rotate(0) scale(1, 1);
  }
`

const FlinchDisappearKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const FlinchInvertKeyframes = keyframes`
  10% {
    filter invert(0%);
  }
  15% {
    filter invert(100%);
  }
  100% {
    filter invert(0%);
  }
`

const _TheWriggler = styled.div<{ hasBeenDamaged: boolean }>`
  z-index: 1;
  max-width: 600px;
  margin: -7% auto 0;
  pointer-events: none;
 
  animation-name: ${({ hasBeenDamaged }) => hasBeenDamaged ? FlinchKeyframes : 'none'};
  animation-duration: 0.35s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`

const TheWrigglerSvg = styled.img< { hasBeenDamaged: boolean, hasBeenDefeated: boolean } >`
  position: absolute;
  max-width: 600px;
  z-index: 1;
  
  animation-name: ${({ hasBeenDamaged, hasBeenDefeated }) => {
    if (hasBeenDefeated) return KillKeyframes
    return hasBeenDamaged ? FlinchDisappearKeyframes : 'none'
  }};
  ${({hasBeenDefeated}) => hasBeenDefeated ? 'animation-fill-mode: forwards; opacity: 0;' : ''}
  animation-duration: 0.45s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`

const TheWrigglerXSvg = styled.img<{ hasHolyPower: boolean, hasBeenDamaged: boolean, hasBeenDefeated: boolean }>`
  filter:
    drop-shadow( 0 0 90px #b380ffAA)
    drop-shadow( 0 0 20px rgba(255, 255, 255, 0.5));
   
   ${({ hasHolyPower, hasBeenDamaged, hasBeenDefeated }) => hasHolyPower ? css`
    animation-name: ${hasBeenDefeated ? KillKeyframes : hasBeenDamaged ? FlinchInvertKeyframes : 'none'};
    ${hasBeenDefeated ? 'animation-fill-mode: forwards; animation-duration: 1s; animation-iteration-count: 1; animation-timing-function: ease-out; transform-origin: 70% 50%;' : 'animation-duration: 0.12s; animation-iteration-count: 1; animation-timing-function: linear;'}
   `: ''}
  
`

const DebugText = styled.div`
  color: white;
`

const DamagesWrap = styled.div`
    max-width: 600px;
    margin: -7% auto 0;
`

const DamagesContainer = styled.div`
  z-index: 2;
  position: absolute;
`

const DamageFadeKeyframes = keyframes`
  0% {
    transform: translate(0);
    opacity: 1;
  }
  
  50% {
    opacity: 1;  
  }
  100% {
    transform: translate(0, -30px);
    opacity: 0;  
  }
`

const DamageBox = styled.div<{ offsetX: number, offsetY: number }>`
  font-family: "DePixel", Monaco;
  font-size: min(30px, 4.7vw);
  color: #cf0023;
  text-shadow: 0px 0px 2px #ffffff, 0px 0px 2px #ffffff, 0px 0px 2px #ffffff;
  position: absolute;
  left: ${({ offsetX }) => offsetX * 6}px;
  top: ${({ offsetY }) => offsetY * 6}px;
  @media(max-width: 600px) {
    left: ${({ offsetX }) => offsetX}vw;
    top: ${({ offsetY }) => offsetY}vw;
  }
  opacity: 0;
  animation-name: ${DamageFadeKeyframes};
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
`

const HealBox = styled.div<{ offsetX: number, offsetY: number, delay: number; }>`
  font-family: "DePixel", Monaco;
  font-size: min(30px, 4.7vw);
  color: #00cf2d;
  text-shadow: 0px 0px 2px #ffffff;
  position: absolute;
  left: ${({ offsetX }) => offsetX * 6}px;
  top: ${({ offsetY }) => offsetY * 6}px;
  @media(max-width: 600px) {
    left: ${({ offsetX }) => offsetX}vw;
    top: ${({ offsetY }) => offsetY}vw;
  }
  opacity: 0;
  animation-name: ${DamageFadeKeyframes};
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: ${({ delay }) => delay}ms;
`

const ComboKeyframes = keyframes`
  0% {
    transform: translate(-20px, 0) rotate(-270deg) scale(5, 5);
    opacity: 0;
  }
  15% {
    transform: translate(0, 0) rotate(0)  scale(1, 1);
    opacity: 1;
  }
  80% {
    transform: translate(10px, 0);
    opacity: 1;
  }
  100% {
    transform: translate(30px, 0) scale(3, 3);
    opacity: 0;
  }
`

const ComboKeyframesMobile = keyframes`
  0% {
    transform: translate(-20px, 0);
    opacity: 0;
  }
  15% {
    transform: translate(0, 0) scale(1, 1);
    opacity: 1;
  }
  80% {
    transform: translate(10px, 0);
    opacity: 1;
  }
  100% {
    transform: translate(30px, 0);
    opacity: 0;
  }
`

const ComboBox = styled.div<{ offsetX: number, offsetY: number }>`
  position: absolute;
  font-family: "DePixel", Monaco;
  font-size: min(30px, 4.7vw);
  color: var(--felixGreen);
  font-style: italic;
  left: ${({ offsetX }) => offsetX * 6}px;
  top: ${({ offsetY }) => offsetY * 6}px;
  @media(max-width: 600px) {
    left: ${({ offsetX }) => offsetX}vw;
    top: ${({ offsetY }) => offsetY}vw;
  }
  
  opacity: 0;
  @media(max-width: 600px) {
    animation-name: ${ComboKeyframesMobile};
  }
  @media(min-width: 601px) {
    animation-name: ${ComboKeyframes};
  }
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 1s;
`