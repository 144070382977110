import {useWeb3Context} from "../web3-context/Web3Context";
import {useEffect, useRef, useState} from "react";
import {BigNumber, Contract, Event} from "ethers";
import {isLocalDev, theWrigglerAddress} from "../settings";
import TheWrigglerAbi from "../custom-abis/TheWriggler.abi.json"
import styled from "styled-components";
import {Wrath} from "./Wrath";
import autoAnimate from "@formkit/auto-animate";
import ensCache from "../ensCache.json"

interface HeathenData {
  tokenId: number
  address: string
}

export function HeathenList() {
  const { provider, currentBlockNumber } = useWeb3Context()
  const [transferLogs, setTransferLogs] = useState<Event[]>(ensCache.transferLogs as unknown as Event[])
  const lastBlockCheckRef = useRef(ensCache.blockNumber)
  const parent = useRef(null)

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  useEffect(() => {
    if (currentBlockNumber && provider) {
      const theWriggler = new Contract(theWrigglerAddress, TheWrigglerAbi.abi, provider);
      (async () => {
        const nextTransferLogs = await theWriggler.queryFilter(
          theWriggler.filters.Transfer(),
          lastBlockCheckRef.current + 1,
          currentBlockNumber,
        )
        setTransferLogs([...transferLogs, ...nextTransferLogs])
        lastBlockCheckRef.current = currentBlockNumber
      })()
    }
  }, [currentBlockNumber])



  const currentHolder: HeathenData | null = !transferLogs.length ? null
    : transferLogs.length == 2 ? {
      tokenId: BigNumber.from(transferLogs.slice(-1)[0].args?.[2]).toNumber() + 1,
      address: transferLogs.slice(-1)[0].args?.[1],
    } : {
      tokenId: BigNumber.from(transferLogs.slice(-1)[0].args?.[2]).toNumber() + 1,
      address: transferLogs.slice(-2)[0].args?.[1],
    }

  const openOpenSea = (tokenId: number) => {
    const url = `https://opensea.io/assets/ethereum/${theWrigglerAddress}/${tokenId}`
    window.open(url, '_blank')?.focus()
  }

  const heathenData = transferLogs.reduce<HeathenData[]>((data, transfer, i) => {
    const is0minous = BigNumber.from(transfer.args?.[2]).toNumber() === 0
    if (is0minous) return data
    data.push({
      address: transfer.args?.[1],
      tokenId: BigNumber.from(transfer.args?.[2]).toNumber(),
    })
    return data
  }, [])
    .reverse()

  const spacer = <_HeathenListItem
    style={{ visibility: 'hidden' }}
  />

  return <HeathenListContainer ref={parent}>
      {
        currentHolder ?
          <HeathenListItem onClick={() => openOpenSea(0)} isCurrentHolder={true} address={currentHolder.address} tokenId={currentHolder.tokenId} key={currentHolder.tokenId}/>
          : spacer
      }
    {heathenData.length ? <Wrath/> : null}
      {heathenData.map(({ address, tokenId }) => {
        return <HeathenListItem onClick={() => openOpenSea(tokenId)} address={address} key={tokenId} tokenId={tokenId} />
      })}
  </HeathenListContainer>
}

function HeathenListItem({
  address,
  tokenId,
  isCurrentHolder = false,
  onClick,
}: { address: string, tokenId: number, key: number, isCurrentHolder?: boolean, onClick: () => void, style?: any }) {
  const { provider } = useWeb3Context()
  const [ensName, setEnsName] = useState<string | null>(null)

  useEffect(() => {
    if (isLocalDev) return;
    (async () => {
      const cachedName = (ensCache.names as Record<string, string|null>)[address.toLowerCase()]
      if (cachedName) {
        setEnsName(cachedName)
      } else if (cachedName !== null) {
        console.log('cache miss!')
        const name = await provider.lookupAddress(address)
        if (name) {
          setEnsName(name)
        }
      }
    })()
  }, [])

  if (isCurrentHolder) {
    return <CurrentHolder onClick={onClick}>
      <TokenId>#{tokenId}</TokenId>
      <Address>
        {ensName ?? address}
      </Address>
    </CurrentHolder>
  }

  return <_HeathenListItem onClick={onClick}>
      <TokenId>#{tokenId}</TokenId>
    <Address>
      {ensName ?? address}
    </Address>
  </_HeathenListItem>
}

const HeathenListContainer = styled.div`
  max-width: 580px;
  margin: 0 auto;
`

const CurrentHolder: any = styled.div`
  display: flex;
  align-items: center;
  color: var(--felixGreen);
  margin: 15px;
  max-width: 560px;
  border: 4px solid var(--felixGreen);
  padding: 1rem;
  height 40px;
  border-radius: 0.25rem;
  background-color: var(--midnightPurple);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.05, 1.05);
    font-weight: bold;
    background: #2f0434;
  }
`

const _HeathenListItem: any = styled.div`
  display: flex;
  align-items: center;
  color: var(--wrigglerRed);
  margin: 15px;
  max-width: 560px;
  border: 1px solid var(--accentPurple);
  padding: 1rem;
  height 40px;
  border-radius: 0.25rem;
  background-color: var(--midnightPurple);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.05, 1.05);
    font-weight: bold;
    background: #2f0434;
  }
`

const TokenId = styled.div`
  padding: 0 1rem;
`

const Address = styled.div`
  margin-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const HeathenListLeft = styled.div`
  display: flex;
  flex-direction: column;
`