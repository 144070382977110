import {Button} from "./shared/Button";
import styled from "styled-components";
import {ConnectButton} from "./ConnectButton";
import {NavLink} from "react-router-dom";

export function TopBar() {
  return <_TopBar>
    <NavLink to="/">
      <Button onClick={() => {}}>
        <div>Wriggler Cult</div>
      </Button>
    </NavLink>
    <NavLink to="/pact">
      <Button onClick={() => {}}>
        <div>Pronto Pact</div>
      </Button>
    </NavLink>
    <ConnectButton />
  </_TopBar>
}

const _TopBar = styled.div`
  margin: 16px 30px;
  display: flex;
  gap: 0.5rem;

  & > .active {
    display: none;
  }
`
