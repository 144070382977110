import styled from "styled-components";

export function Wrath() {
  return <_Wrath>
    WRATH OF THE WRIGGLER
  </_Wrath>
}

const _Wrath = styled.div`
  margin: 22px auto 18px;
  color: var(--wrigglerRed);
  font-size: 26px;
  font-weight: 700;
  text-align: center;
`