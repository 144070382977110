import "./Loading.css"


export function Loading() {
  return <div className="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
}
