import styled from "styled-components";

export function Description({ isDefeated }: any) {
  return <_Description isDefeated={isDefeated}>
    The Wriggler is an NFT on a mission to visit every wallet on the Ethereum blockchain <Italics>FASTER THAN MY BROTHER EVER COULD. </Italics>
    When The Wriggler visits you, it brings nonbelievers misery and horror that will last their pathetic lifetimes.
  </_Description>
}

const _Description = styled.div<{ isDefeated: boolean }>`
  margin: 35px auto;
  color: white;
  font-size: 1rem;
  line-height: 1.5em;
  text-align: center;
  font-weight: 700;
  max-width: 550px;
  padding: 0 16px;
  ${({ isDefeated }) => isDefeated ? 'text-decoration: line-through;' : ''}
`

const Italics = styled.span`
  font-style: italic;
`