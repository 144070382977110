import styled, {css} from "styled-components";
import React from "react";
import {Loading} from "../Loading";

export function BigButton({ children, onClick, enabled, loading }: { children: JSX.Element, onClick: () => void, enabled: boolean, loading: boolean } ) {
  // @ts-ignore
  return <Wrapper enabled={enabled}><_Button onClick={onClick} enabled={enabled} loading={loading}>
    {loading ? <Loading /> : children}
  </_Button></Wrapper>
}

const _Button = styled.button<{ enabled: boolean, loading: boolean }>`
  font-size: 2em;
  background-color: black;
  color: var(--felixGreen);
  border: 1px solid var(--felixGreen);
  border-radius: var(--radii-base);
  transition: var(--transitions-1);
  padding: 11px 13px;
  
  ${({ enabled, loading }) => {
    if (enabled && !loading) {
      return css`
        &:hover {
          background-color: var(--accentPurple);
          cursor: pointer;
          color: var(--wrigglerRed);
        }
      `
    }
  }}
  ${({ enabled, loading }) => {
    if (!enabled || loading) {
      return css`
        opacity: 0.5;
        pointer-events: none;
      `
    }  
  }}
`
const Wrapper = styled.div<{ enabled: boolean }>`
  ${({ enabled }) => {
  if (!enabled) {
    return css`
        cursor: not-allowed !important;
      `
  }
}}
`