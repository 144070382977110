import {useEffect, useRef, useState} from "react";

interface Job<T extends any[] = any[]> {
  fn: (...args: T) => any | Promise<any>
  args?: T
}

function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function useIntervalJobQueue(intervalMs: number) {
  const jobQueue = useRef<Job[]>([])
  const [currentlyProcessing, setCurrentlyProcessing] = useState(false)
  const [lastJobStartTime, setLastJobStartTime] = useState(0)
  const [nonce, setNonce] = useState(0)

  function addJob(job: Job) {
    jobQueue.current.push(job)
    setNonce((n) => n + 1) // triggers job queue to process if not already
  }

  useEffect(() => {
    if (!currentlyProcessing) {
      setCurrentlyProcessing(true);
      let innerLastJobStartTime = lastJobStartTime;
      (async () => {
        while (jobQueue.current.length) {
          let now = Date.now()
          const waitTime = Math.min(intervalMs, Math.max(0, intervalMs - (now - innerLastJobStartTime)))
          if (waitTime > 1000/60) {
            await wait(waitTime)
          }
          const job = jobQueue.current.shift()
          now = Date.now()
          setLastJobStartTime(now)
          innerLastJobStartTime = now
          await job!.fn(...(job!.args ?? []))
        }
        setCurrentlyProcessing(false);
      })()
    }
  }, [nonce])

  return { addJob }
}