import {createContext, useContext, useEffect, useState} from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { WalletLink } from "walletlink";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { JsonRpcProvider, InfuraProvider, JsonRpcSigner } from "@ethersproject/providers";
import CoinbaseLogoSvg from './coinbase.svg'

let offlineProvider: JsonRpcProvider;
if (process.env.REACT_APP_LOCAL_CHAIN === "true") {
  offlineProvider = new JsonRpcProvider("http://localhost:8545");
} else {
  offlineProvider = new InfuraProvider("mainnet", process.env.REACT_APP_INFURA_ID);
}

let lastSeenBlockNumber = 0

export const Web3Context = createContext({
  provider: offlineProvider,
  signer: null as JsonRpcSigner | null,
  connect: async () => {},
  disconnect: async () => {},
  currentBlockNumber: null as null | number,
  activeAddress: null as string | null,
  chainId: offlineProvider.network?.chainId ?? 31337,
});

export function useWeb3Context() {
  return useContext(Web3Context);
}


const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: { infuraId: process.env.REACT_APP_INFURA_ID },
    },
    "custom-walletlink": {
      display: {
        logo: CoinbaseLogoSvg,
        name: "WalletLink",
        description: "Scan with WalletLink to connect",
      },
      options: {
        appName: "The Wriggler", // Your app name
        networkUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
        chainId: 1,
      },
      package: WalletLink,
      connector: async (_, options) => {
        const { appName, networkUrl, chainId } = options;
        const walletLink = new WalletLink({
          appName,
        });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    },
  },
});

let gotFirstBlockNumber = false

export function Web3Provider({ children }: { children: any }) {
  const [instance, setInstance] = useState<any | null>(null);
  const [provider, setProvider] = useState<JsonRpcProvider>(offlineProvider);
  const [signer, setSigner] = useState<JsonRpcSigner | null>(null);
  const [chainId, setChainId] = useState<number>(1);
  const [activeAddress, setActiveAddress] = useState<string | null>(null);
  const [currentBlockNumber, setCurrentBlockNumber] = useState<number | null>(null);

  useEffect(() => {
    if (window.ethereum) {
      ["chainChanged", "chainIdChanged", "accountsChanged"].forEach((eventName) => {
        window.ethereum?.on(eventName, connect);
      });
    }
    return () => {
      if (window.ethereum) {
        ["chainChanged", "chainIdChanged", "accountsChanged"].forEach((eventName) => {
          window.ethereum?.removeListener(eventName, connect);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (web3Modal.cachedProvider === "injected") {
      connect();
    }
  }, []);

  useEffect(() => {
    const cb = (blockNumber: number) => {
      if (!gotFirstBlockNumber) {
        gotFirstBlockNumber = true
        const firstBlockNumber = blockNumber
        setTimeout(() => {
          setCurrentBlockNumber((b) => {
            if (!b) return firstBlockNumber
            return b
          })
        }, 1000)
      } else {
        if (blockNumber > lastSeenBlockNumber) {
          setCurrentBlockNumber(blockNumber)
          lastSeenBlockNumber = blockNumber
        }
      }
    }
    if (provider) {
      provider.on('block', cb)
    }
    return () => {
      if (provider) {
        provider.removeListener('block', cb)
      }
    }
  }, [provider])

  async function connect() {
    const instance = await web3Modal.connect();
    const _provider = new ethers.providers.Web3Provider(instance);
    const _signer = _provider.getSigner();
    const network = await _provider.getNetwork();
    const accounts = await _provider.listAccounts();
    setInstance(instance);
    setProvider(_provider);
    setSigner(_signer);
    setChainId(network.chainId);
    setActiveAddress(accounts[0]);
  }

  async function disconnect() {
    if (instance && instance.currentProvider && instance.currentProvider.close) {
      await instance.currentProvider.close();
    }
    await web3Modal.clearCachedProvider();
    setInstance(null);
    setProvider(offlineProvider);
    setSigner(null);
    setActiveAddress(null);
    setChainId(offlineProvider.network.chainId);
  }

  return (
    <Web3Context.Provider
      value={{
        provider,
        signer,
        activeAddress,
        chainId,
        currentBlockNumber,
        connect,
        disconnect,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}
