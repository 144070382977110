import React from "react";
import styled from "styled-components";
import background from "./svgs/background.svg";
import "./App.css";
import {TopBar} from "./components/TopBar";
import {Web3Provider} from "./web3-context/Web3Context";

function App(props: {children: React.ReactElement}) {
  return (
    <Web3Provider>
      <>
        <_App className="App">
          <TopBar />
          {props.children}
        </_App>
      </>
      <Footer src={background} />
    </Web3Provider>
  );
}

export default App;

const _App = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background: transparent;
`

const Footer = styled.img`
  position: fixed;
  bottom: 0px;
  left: 25vw;
  margin-left: -50%;
  z-index: -1;
  min-width: 2000px;
`;