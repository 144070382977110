import InfinitySvg from "../svgs/infinity.svg"
import styled from "styled-components";

export function InfinityAnimation() {
  return <_InfinityAnimation>
    <img src={InfinitySvg} />
  </_InfinityAnimation>
}

const _InfinityAnimation = styled.div`
  margin: 0 auto 0;
  width: 256px;
`