import styled from "styled-components";
import React from "react";

export function Button({ children, onClick }: { children: JSX.Element, onClick: () => void } ) {
  return <_Button onClick={onClick}>
    {children}
  </_Button>
}

const _Button = styled.button`
  font-size: 1em;
  background-color: black;
  color: var(--accentPurple);
  border: 1px solid var(--accentPurple);
  border-radius: var(--radii-base);
  transition: var(--transitions-1);
  padding: 9px 11px;
  
  &:hover {
    background-color: var(--accentPurple);
    cursor: pointer;
    color: var(--felixGreen);
  }
`
